import Plausible, { EventOptions } from 'plausible-tracker';

let plausible: ReturnType<typeof Plausible>;

export function initPlausible() {
  plausible = Plausible({ trackLocalhost: false });
  plausible.enableAutoPageviews();
}

type EventNames =
  'longflex/tender/view'
  | 'longflex/tender/recommend/save-template'
  | 'longflex/tender/recommend/run-recommendation'
  | 'dev-playground/test-event'

export function trackEventInPlausible(eventName: EventNames, eventOptions?: EventOptions) {
  plausible.trackEvent(eventName, eventOptions);
}
